import React, { useState, useEffect, useRef } from "react";
import "./ProcessedCandidateByJob.css";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCandidateData } from "../../data/candidatedata";
import MarkFavorite from "../../components/MarkFavorite/MarkFavorite";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { storage } from "../../libs/localStorage";
import { Helmet } from "react-helmet";
import { useJobsData } from "../../data/jobsdata";
import { commonData } from "../../data/commondata";
import ScreeningMessagePopup from "../../components/Screening/ScreeningMessagePopup";

const SortData = [
  {
    id: "latest",
    name: "Newest First",
    order: "DESC",
  },
  // {
  //   id: "atoz",
  //   name: "A to Z",
  //   order: "ASC",
  // },
  // {
  //   id: "percentage",
  //   name: "Match %",
  //   order: "DESC",
  // },
  {
    id: "favorite",
    name: "Favorites First",
    order: "DESC",
  },
];
const capitalizeWords = (str) => {
  return str?.replace(/\b\w/g, (char) => char?.toUpperCase());
};
function ProcessedCandidateByJob() {
  const [toggleView, setToggleView] = useState(false);
  const [detailsView, setDetailsView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const { GetCustomerById } = useCandidateData();
  const params = location.state;
  const { retrieveToken } = storage();
  const [data, setData] = useState({});
  const {
    GetProcessedCandidateByJob,
    MarkAnalysisFavorite,
    GetCvStatus,
    UpdateCvStatus,
    AddScreeningNotes,
  } = useCandidateData();
  const { GetDownloadUrl } = commonData();
  const navigate = useNavigate();
  const activetab = "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const token = retrieveToken();
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const [isthirdOpen, setthirdIsOpen] = useState(false);
  const [CvStatusfilterdata, setCvStatusFilterdata] = useState("Status");
  const [filterdata, setFilterdata] = useState("Sort By");
  const [customer, setcustomer] = useState(null);
  const [CvStatus, setCvStatus] = useState(null);
  const [screeningNote, setScreeningNote] = useState("");
  const [analysisid, setAnalysisid] = useState(null);
  const [CvStatusMessage, setCvStatusMessage] = useState();
  const [selectedFilterdata, setSelectedFilterdata] = useState("percentage");
  const [selectedCvStatusFilterdata, setSelectedCvStatusFilterdata] =
    useState("All");
  const [sortOrder, setSortOrder] = useState("DESC");
  const userType = localStorage.getItem("usertype");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const searchTextChange = (event) => {
    setSearchText(event.target.value);
    setSortOrder("");
    setSelectedFilterdata("");
    setSelectedCvStatusFilterdata("All");
    setCvStatusFilterdata("Status");
    setFilterdata("Sort By");
  };
  const [notificationActive, setNotificationActive] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const handleClick = () => {
    setNotificationActive(!notificationActive);
  };
  const { GetAtsUrlByAtsJobcode, MarkJobFavorite } = useJobsData();

  const toggleSecondDropdown = () => {
    setSecondIsOpen((prev) => !prev);
  };

  const handleSecondOptionClick = (Secondoption) => {
    setSelectedFilterdata(Secondoption.id);
    setFilterdata(Secondoption.name);
    setSortOrder(Secondoption.order);
    toggleSecondDropdown();
  };

  const toggleThirdDropdown = () => {
    setthirdIsOpen((prev) => !prev);
  };

  const handleThirdOptionClick = (data) => {
    setSelectedCvStatusFilterdata(data.cvstatusname);
    setCvStatusFilterdata(data.cvstatusname);

    toggleThirdDropdown();
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const downloadResume = (url) => {
    url &&
      GetDownloadUrl({
        url: url,
      })
        .then((response) => {
          window.open(response?.data?.url, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
  };

  useEffect(() => {
    if (token) {
      GetCustomerById()
        .then((response) => {
          setcustomer(response.data[0]);
          response?.data[0]?.isdetailview === true && setDetailsView(true);
        })
        .catch((error) => {
          console.error(error);
        });
      GetProcessedCandidateByJob({
        jobid: params?.jobid,
        search: searchText,
        cvstatus: selectedCvStatusFilterdata,
        sortby: selectedFilterdata || "",
        sortorder: sortOrder,
      })
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(
              response?.data?.map((x) => ({
                ...x,
                candidatesdata: JSON.parse(x?.candidatesdata),
              }))
            );
            setNoDataAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      GetCvStatus()
        .then((response) => {
          setCvStatus(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  useEffect(() => {
    // selectedFilterdata &&
    GetProcessedCandidateByJob({
      jobid: params?.jobid,
      search: searchText,
      cvstatus: selectedCvStatusFilterdata,
      sortby: selectedFilterdata || "",
      sortorder: sortOrder,
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(
            response?.data?.map((x) => ({
              ...x,
              candidatesdata: JSON.parse(x?.candidatesdata),
            }))
          );
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedFilterdata, selectedCvStatusFilterdata, sortOrder]);

  const btnMarkFavorite = (cand) => {
    MarkAnalysisFavorite({
      analysisid: cand?.analysisid,
      isfavorite: !cand?.isfavorite,
    })
      .then((response) => {
        const itemIndex = data[0]?.candidatesdata.findIndex(
          (item) => item.analysisid === cand?.analysisid
        );

        if (itemIndex !== -1) {
          const updatedCandidatesData = [...data[0]?.candidatesdata];
          updatedCandidatesData[itemIndex] = {
            ...updatedCandidatesData[itemIndex],
            isfavorite: !cand?.isfavorite,
          };

          // Sort the updatedCandidatesData based on isfavorite flag (favorites first)
          // updatedCandidatesData.sort((a, b) => {
          //   if (a.isfavorite && !b.isfavorite) return -1;
          //   if (!a.isfavorite && b.isfavorite) return 1;
          //   return 0;
          // });
          setData([{ ...data[0], candidatesdata: updatedCandidatesData }]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const MarkJobFavoriteApi = (job) => {
    MarkJobFavorite({
      jobid: job?.jobid,
      isfavorite: !job?.isfavorite,
    })
      .then((response) => {
        const itemIndex = data?.findIndex((item) => item?.jobid === job?.jobid);
        if (itemIndex !== -1) {
          const updatedJobData = [...data];
          updatedJobData[itemIndex] = {
            ...updatedJobData[itemIndex],
            isfavorite: !job?.isfavorite,
          };
          setData(updatedJobData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const buttonHandle = () => {
    setToggleView(false);
    setDetailsView(!detailsView);
  };
  const buttonHandle2 = () => {
    // setToggleView(!toggleView);
    setDetailsView(false);
  };

  const clearSearch = () => {
    setSearchText("");
    setSortOrder("DESC");
    setSelectedFilterdata("percentage");
    setSelectedCvStatusFilterdata("All");
    setFilterdata("Sort By");
    setCvStatusFilterdata("Status");
    GetProcessedCandidateByJob({
      jobid: params?.jobid,
      search: "",
      sortby: selectedFilterdata || "",
      cvstatus: selectedCvStatusFilterdata || "",
      sortorder: "DESC",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(
            response?.data?.map((x) => ({
              ...x,
              candidatesdata: JSON.parse(x?.candidatesdata),
            }))
          );
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const btnSearch = () => {
    GetProcessedCandidateByJob({
      jobid: params?.jobid,
      search: searchText,
      cvstatus: selectedCvStatusFilterdata || "All",
      sortby: selectedFilterdata || "",
      sortorder: sortOrder,
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(
            response?.data?.map((x) => ({
              ...x,
              candidatesdata: JSON.parse(x.candidatesdata),
            }))
          );
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const redirectToAts = (params) => {
    const { atsjobcode, atsid } = params;
    if (atsid) {
      GetAtsUrlByAtsJobcode({
        atsjobcode: atsjobcode,
      })
        .then((response) => {
          window.open(response?.data[0]?.atsurl, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  };
  const secondDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setSecondIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // For Date and time function
  function formatDateTime(dateString) {
    const date = new Date(dateString);

    // Format the date as MM/DD/YY
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });

    // Format the time as HH:MMam/pm
    let formattedTime = date
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(" ", " ")
      .toUpperCase(); // Remove the space and convert to lowercase

    return `${formattedDate} ${formattedTime}`;
  }
  function formatDateTime2(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });

    return `${formattedDate} `;
  }
  function getOpenDayLabel(openDays) {
    const dayLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();
    const openDate = new Date(today);
    openDate.setDate(today.getDate() - openDays);

    const daysAgo = Math.floor((today - openDate) / (1000 * 60 * 60 * 24));
    const openDayOfWeek = openDate.getDay();

    if (daysAgo === 0) {
      return "Opened Today";
    } else if (daysAgo === 1) {
      return "Opened Yesterday";
    } else if (daysAgo < 7) {
      return `Opened ${dayLabels[openDayOfWeek]}`;
    } else {
      return `Opened on\n${openDate
        .toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-")}`;
    }
  }
  const yourColumnsArray = [
    { id: 1, title: "Column 1", isVisible: true },
    { id: 2, title: "Column 2", isVisible: true },
    { id: 3, title: "Column 7", isVisible: true },
    { id: 4, title: "Column 3", isVisible: customer?.show_deni },
    { id: 5, title: "Column 4", isVisible: customer?.ismiddleeast },
    { id: 6, title: "Column 5", isVisible: customer?.isgender },
    { id: 7, title: "Column 6", isVisible: customer?.iscurrentjoblocation },
    { id: 8, title: "Column 8", isVisible: true },
    { id: 9, title: "Column 9", isVisible: true },
    { id: 10, title: "Column 10", isVisible: true },
    { id: 11, title: "Column 11", isVisible: true },
    { id: 12, title: "Column 12", isVisible: customer?.isexperiencesimiliar },
    { id: 13, title: "Column 13", isVisible: customer?.iscustomcriticalskill },
    { id: 14, title: "Column 14", isVisible: customer?.ishighestqualification },
    { id: 15, title: "Column 15", isVisible: customer?.isdrivinglic },
    { id: 16, title: "Column 16", isVisible: customer?.isarabicspeaking },
    { id: 17, title: "Column 17", isVisible: customer?.isoverqualified },
    { id: 18, title: "Column 18", isVisible: customer?.isoverexperienced },
    { id: 19, title: "Column 19", isVisible: customer?.isage },
    { id: 20, title: "Column 20", isVisible: true },
    { id: 21, title: "Column 21", isVisible: true },
  ];

  const totalColumns = 21;
  const fullWidth = 3400; // Maximum width when all columns are visible
  const columnWidth = fullWidth / totalColumns;

  // Calculate the current table width based on visible columns
  const visibleColumns = yourColumnsArray.filter(
    (column) => column.isVisible
  ).length;
  const tableWidth = Math.round(visibleColumns * columnWidth);

  const handleStatusChange = (id, newStatus) => {
    UpdateCvStatus({
      analysisid: id,
      status: newStatus,
    })
      .then((response) => {
        setCvStatusMessage(response?.header?.message);

        if (response?.header?.status === 200) {
          if (response?.header?.message === "CV status updated successfully") {
            toast.success(
              <span
                style={{
                  font: "rubik regular",
                  color: "black",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                {response?.header?.message}
              </span>
            );

            // Call GetProcessedCandidateByJob to get the latest data
            GetProcessedCandidateByJob({
              jobid: params?.jobid,
              search: searchText,
              cvstatus: selectedCvStatusFilterdata,
              sortby: selectedFilterdata || "",
              sortorder: sortOrder,
            })
              .then((response) => {
                setData(
                  response?.data?.map((x) => ({
                    ...x,
                    candidatesdata: JSON.parse(x?.candidatesdata),
                  }))
                );
              })
              .catch((error) => {
                console.error("Error fetching updated data:", error);
              });
          } else {
            toast.error(
              <span
                style={{
                  font: "rubik regular",
                  color: "black",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
              >
                {response?.header?.message}
              </span>
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error updating CV status:", error);
      });
  };

  const handleItemClick = (item) => {
    setAnalysisid(item?.analysisid);
    setScreeningNote(item?.screeningnote);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setAnalysisid(null);
    setScreeningNote("");
    setErrorMessage("");
  };

  const handleSaveChanges = () => {
    if (analysisid !== null) {
      // if (screeningNote?.trim()) {
      AddScreeningNotes({
        screeningnote: screeningNote?.trim(),
        analysisid: analysisid,
      })
        .then((response) => {
          response?.header?.status == 200
            ? toast.success(
                <span
                  style={{
                    font: "rubik regular",
                    color: "black",
                    fontWeight: "normal",
                    fontSize: "15px",
                  }}
                >
                  Screening note updated
                </span>
              )
            : toast.error(
                <span
                  style={{
                    font: "rubik regular",
                    color: "black",
                    fontWeight: "normal",
                    fontSize: "15px",
                  }}
                >
                  {response?.header?.message}
                </span>
              );
          const itemIndex = data[0]?.candidatesdata?.findIndex(
            (item) => item?.analysisid === analysisid
          );

          if (itemIndex !== -1) {
            const updatedCandidatesData = [...data[0]?.candidatesdata];
            updatedCandidatesData[itemIndex] = {
              ...updatedCandidatesData[itemIndex],
              screeningnote: screeningNote,
            };
            setData([{ ...data[0], candidatesdata: updatedCandidatesData }]);
          }
          handleClosePopup();
        })
        .catch((error) => {
          console.error(error);
        });
      // } else {
      //   setErrorMessage("Screening note is required.");
      // }
    }
  };

  const handleInputChange = (e) => {
    setScreeningNote(e.target.value);
  };
  const message = data[0]?.candidatesdata?.filter(
    (candidate) => candidate?.screeningnote
  );

  const messageStus = message?.map((items) => items);

  return (
    <>
      <ToastContainer />
      <ScreeningMessagePopup
        analysisid={analysisid}
        screeningNote={screeningNote}
        handleInputChange={handleInputChange}
        handleSaveChanges={handleSaveChanges}
        handleClosePopup={handleClosePopup}
        isPopupOpen={isPopupOpen}
        errorMessage={errorMessage}
        isSave={
          data?.screeningnote == "" || data?.screeningnote == null
            ? true
            : false
        }
      />
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Processed Job</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>
          <React.Fragment>
            <div
              style={{
                position: "sticky",
                top: "0",
                background: "white",
                paddingBottom: "7px",
              }}
            >
              <div class="stopper">
                <div class="stopper-child">
                  <img
                    onClick={() => window.history.back()}
                    src="/images/Back.svg"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div class="stopper-child">
                  <p style={{ color: "#21D6AA", fontWeight: "500" }}>
                    NOTIFY ME &nbsp;&nbsp;
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </p>
                </div>
              </div>

              <div
                className="search-container"
                style={{ margin: "18px 17px 8px 17px" }}
              >
                <input
                  type="text"
                  id="search"
                  placeholder="Search Candidate"
                  style={{
                    border: "none",
                    outline: "none",
                    height: "40px",
                    paddingLeft: "10px",
                    width: "200px",
                    flex: 1,
                  }}
                  value={searchText}
                  onChange={searchTextChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") btnSearch();
                  }}
                />

                {searchText && (
                  <button
                    style={{
                      position: "absolute",
                      right: "60px",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={clearSearch}
                  >
                    <img
                      src="/images/greenCross1.svg"
                      style={{ width: "10px", height: "10px" }}
                      alt="Clear"
                    />
                  </button>
                )}

                <button
                  style={{
                    border: "none",
                    outline: "none",
                    height: "40px",
                    paddingTop: "9px",
                  }}
                  type="submit"
                  onClick={() => btnSearch()}
                >
                  <img
                    style={{ background: "inherit" }}
                    src="/images/Search Icon.svg"
                  />
                </button>
              </div>

              <div
                className="dropdown_sort_by"
                style={{ padding: "15px 15px 5px 15px" }}
              >
                <button
                  onClick={toggleSecondDropdown}
                  className={`dropdown_sort ${
                    isSecondOpen ? "secondopen" : ""
                  }`}
                >
                  {filterdata || "Sort By"}
                  {!isSecondOpen ? (
                    <img src="images/custom-arrow.svg" />
                  ) : (
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      src="images/custom-arrow.svg"
                    />
                  )}
                </button>

                {isSecondOpen && (
                  <div className="dropdown_sort_by_list_mobile">
                    <div>
                      {SortData &&
                        SortData?.map((data, i) => (
                          <p
                            key={i}
                            onClick={() => handleSecondOptionClick(data)}
                          >
                            {data?.name}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
              </div>

              <div class="jobid-more-mobile">
                <div className="flex justify-center gap-3">
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/jobdescribtion", {
                        state: {
                          jobid: data[0]?.jobid,
                          jobcode: data[0]?.atsjobcode,
                        },
                      })
                    }
                  >
                    <Tooltip
                      title={
                        data[0]?.atsjobcode.length > 20
                          ? data[0]?.atsjobcode
                          : ""
                      }
                    >
                      JOB ID:{" "}
                      {data[0]?.atsjobcode.length > 20
                        ? `${data[0]?.atsjobcode.substring(0, 20)}...`
                        : data[0]?.atsjobcode}
                    </Tooltip>
                  </p>
                  <div className="">
                    {" "}
                    <text> ({data[0]?.candidatesdata?.length})</text>
                  </div>
                </div>
                <p style={{ fontWeight: "600" }}>{data[0]?.jobtitle}</p>
                <p style={{ fontSize: "10px" }}>
                  {/* {data[0]?.opendays > 0
                    ? data[0]?.opendays > 1
                      ? "Opened " + data[0]?.opendays + " Days Ago"
                      : "Opened " + data[0]?.opendays + " Day Ago"
                    : "Opened Today"} */}
                  {getOpenDayLabel(data[0]?.opendays)}
                </p>
              </div>
            </div>

            <div class="complete-list">
              <div class="full-list">
                {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}> */}
                {data &&
                  data[0]?.candidatesdata?.map((candidate, i) => (
                    <div class="details-listing" key={candidate?.analysisid}>
                      <div
                        class="under-listing"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          navigate("/candidateresult", {
                            state: candidate?.analysisid,
                          })
                        }
                      >
                        <p>{candidate?.candidatename}</p>
                      </div>
                      <div
                        class="under-listing"
                        style={{ textAlign: "center" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#708EA4",
                              fontSize: "14px",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              navigate("/candidateresult", {
                                state: candidate?.analysisid,
                              })
                            }
                          >
                            {candidate?.match_percentage}%
                          </p>
                          <img
                            style={{ width: "20px", cursor: "pointer" }}
                            src="/images/arrow.svg"
                            alt=""
                            onClick={(e) =>
                              navigate("/candidateresult", {
                                state: candidate?.analysisid,
                              })
                            }
                          />
                          <MarkFavorite
                            onClick={(e) => btnMarkFavorite(candidate)}
                            imgUrl={
                              candidate?.isfavorite
                                ? "/images/filled-bookmark.svg"
                                : "/images/bookmark.svg"
                            }
                            style={{
                              width: "70%",
                              marginLeft: "3px",
                              marginTop: "4px",
                              cursor: "pointer",
                            }}
                          />
                          {/* <ImageToggle style={{width:'70%',marginLeft:'3px',marginTop:'4px'}} initialSrc="/images/bookmark.svg"/> */}
                        </div>
                      </div>
                    </div>
                  ))}
                {/* </div> */}
              </div>
            </div>
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Processed Job</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>
          <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
            <Navbar data={activetab} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: "80%" }}>
              <div class="analyst" className="flex ">
                <div className="w-[28%] ms-5 ">
                  <button onClick={() => navigate(-1)} className="p-match">
                    <img
                      style={{ width: "16px" }}
                      src="/images/arrow-left.svg"
                      alt=""
                    />
                    Back
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-wrap justify-between items-center mt-2">
                <div className="flex flex-row gap-5 text-[#21d6aa] text-[14px] font-semibold ml-5">
                  <div className="notify-child flex gap-2">
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/jobdescribtion", {
                          state: {
                            jobid: data[0]?.jobid,
                            jobcode: data[0]?.atsjobcode,
                          },
                        })
                      }
                    >
                      JOB ID: {data[0]?.atsjobcode}
                    </p>
                    <text> ({data[0]?.candidatesdata?.length})</text>
                  </div>

                  <div className="uppercase">{data[0]?.jobtitle}</div>

                  {/* <Tooltip
                      title={
                        notificationActive
                          ? ""
                          : "Notify me when new candidates arrive"
                      }
                    >
                      <div
                        class="notify-child"
                        className="flex mt-2.5"
                        onClick={handleClick}
                      >
                        <img
                          src={
                            notificationActive
                              ? "/images/Notify Me Icondark.svg"
                              : "/images/Notify Me Icon.svg"
                          }
                          className="w-6 h-5 cursor-pointer"
                        />
                        <p className="ml-2 cursor-pointer">
                          {notificationActive
                            ? "NOTIFICATION ACTIVE "
                            : "NOTIFY ME"}
                        </p>
                      </div>
                    </Tooltip> */}
                  {/* {userType == "A" && (
                     <div className="mt-[9px] uppercase ">
                     <Link to="/errorresume" className="flex flex-row items-center gap-1 cursor-pointer">
                      <img
                         className="w-5"
                         src="/images/icon-warning.svg"
                         alt="error-icon"
                        />
                       <span>Unprocessed Resumes</span>
                     </Link>
                    </div>
                  )} */}
                  {userType == "A" && (
                    <div
                      className=" uppercase flex flex-row items-center gap-1 cursor-pointer"
                      onClick={() =>
                        navigate("/errorresume", {
                          state: params?.jobid,
                        })
                      }
                    >
                      <img
                        className="w-5"
                        src="/images/icon-warning.svg"
                        alt="error-icon"
                      />
                      <span>Unprocessed Resumes</span>
                    </div>
                  )}
                </div>
                <div className=" flex flex-row gap-2">
                  <div
                    className=" text-[#21d6aa] text-[14px] font-semibold flex gap-2 cursor-pointer items-center"
                    onClick={(e) =>
                      MarkJobFavoriteApi({
                        jobid: data[0]?.jobid,
                        isfavorite: data[0]?.isfavorite,
                      })
                    }
                  >
                    <MarkFavorite
                      imgUrl={
                        data[0]?.isfavorite
                          ? "/images/filled-bookmark.svg"
                          : "/images/bookmark.svg"
                      }
                      style={{ cursor: "pointer" }}
                    />
                    <p>FAVORITE</p>
                  </div>
                  <div className="text-[#708ea4] text-[13px] font-normal">
                    <p>
                      {/* {data[0]?.opendays > 0
                        ? data[0]?.opendays > 1
                          ? "Opened " + data[0]?.opendays + " Days Ago"
                          : "Opened " + data[0]?.opendays + " Day Ago"
                        : "Opened Today"} */}
                      {getOpenDayLabel(data[0]?.opendays)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-between mt-2 gap-3">
                <div
                  className="search-container"
                  style={{
                    marginLeft: "18px",
                    marginTop: "10px",
                    marginBottom: "22px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                  }}
                >
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Candidate"
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingLeft: "10px",
                      width: "200px",
                      flex: 1,
                    }}
                    value={searchText}
                    onChange={searchTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") btnSearch();
                    }}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "40px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "10px", height: "10px" }}
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingTop: "9px",
                    }}
                    type="submit"
                    onClick={() => btnSearch()}
                  >
                    <img
                      style={{ background: "inherit" }}
                      src="/images/Search Icon.svg"
                    />
                  </button>
                </div>
                <div className="flex flex-row justify-between  mt-3 gap-3">
                  <div
                    className="dropdown_sort_by"
                    style={{ width: "250px", zIndex: "99" }}
                    ref={secondDropdownRef}
                  >
                    <button
                      onClick={toggleThirdDropdown}
                      className={`dropdown_sort ${
                        isthirdOpen ? "thirdopen" : ""
                      }`}
                    >
                      {CvStatusfilterdata || "All"}
                      {!isthirdOpen ? (
                        <img src="images/custom-arrow.svg" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                        />
                      )}
                    </button>

                    {isthirdOpen && (
                      <div className="dropdown_sort_by_list">
                        <div>
                          {CvStatus &&
                            [
                              { cvstatusname: "All" }, // Add the "All" option as the first item
                              ...(CvStatus || []),
                            ]?.map((data, i) => (
                              <p
                                key={i}
                                onClick={() => handleThirdOptionClick(data)}
                              >
                                {data?.cvstatusname}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="dropdown_sort_by"
                    style={{ width: "250px", zIndex: "99" }}
                    ref={secondDropdownRef}
                  >
                    <button
                      onClick={toggleSecondDropdown}
                      className={`dropdown_sort ${
                        isSecondOpen ? "secondopen" : ""
                      }`}
                    >
                      {filterdata || "Sort By"}
                      {!isSecondOpen ? (
                        <img src="images/custom-arrow.svg" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                        />
                      )}
                    </button>

                    {isSecondOpen && (
                      <div className="dropdown_sort_by_list">
                        <div>
                          {SortData &&
                            SortData.map((data, i) => (
                              <p
                                key={i}
                                onClick={() => handleSecondOptionClick(data)}
                              >
                                {data?.name}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div
                      class="justify-center w-34 h-10  flex flex-raw"
                      style={{ boxShadow: "0px 0px 20px #5B93ED33" }}
                    >
                      <Tooltip title={"Summary View"}>
                        <button
                          className="w-[42px] flex items-center justify-center"
                          onClick={() => buttonHandle2()}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              !toggleView && !detailsView ? "#21D6AA" : "white",
                          }}
                        >
                          {!toggleView && !detailsView ? (
                            <img
                              className="w-5 "
                              src="/images/white-grid.svg"
                              alt="Button 2"
                            />
                          ) : (
                            <img
                              className="w-5 "
                              src="/images/4-box.svg"
                              alt="Button 2"
                            />
                          )}
                        </button>
                      </Tooltip>
                      {customer?.isdetailview === true && (
                        <Tooltip title={"Detailed View"}>
                          <button
                            className="w-[42px] flex items-center justify-center"
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                detailsView === true ? "#21D6AA" : "white",
                            }}
                            onClick={() => buttonHandle()}
                          >
                            {detailsView ? (
                              <img
                                className="w-12 "
                                src="/images/viewdetails2.svg"
                                alt="Button 1"
                              />
                            ) : (
                              <img
                                className="w-12"
                                src="/images/viewdetails.svg"
                                alt="Button 1"
                              />
                            )}
                          </button>
                        </Tooltip>
                      )}
                      {/* <Tooltip title={" List View"}>
                        <button
                          className="w-[42px] flex items-center justify-center"
                          onClick={() => buttonHandle2()}
                          style={{
                            cursor: "pointer",
                            backgroundColor: toggleView ? "#21D6AA" : "white",
                          }}
                        >
                          {toggleView ? (
                            <img
                              className="w-5 "
                              src="/images/3-dots.svg"
                              alt="Button 1"
                            />
                          ) : (
                            <img
                              className="w-5 "
                              src="/images/green-list.svg"
                              alt="Button 1"
                            />
                          )}
                        </button>
                      </Tooltip> */}
                    </div>
                  </div>
                </div>
              </div>

              {/*listing */}
              {detailsView ? (
                noDataAvailable ? (
                  <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                    <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                      <p className="text-[30px] text-gray-500 font-bold">!</p>
                    </div>
                    <p className="text-[20px] text-gray-500 font-bold">
                      Data not available
                    </p>
                  </div>
                ) : (
                  <>
                    <div
                      className="2xl:min-w-[100%]   overflow-y-hidden ms-5"
                      style={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "auto transparent",
                      }}
                    >
                      <div
                        className={`overflow-auto `}
                        style={{
                          width: `${tableWidth + 100}px`,
                          scrollbarWidth: "thin",
                          scrollbarColor: "auto transparent",
                        }}
                      >
                        <table className="w-full bg-white border border-[#E0E0E0] rounded-sm divide-gray-200">
                          <thead>
                            <tr>
                              <th className="!w-[40px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"></th>

                              <th className="!w-[12px]  text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"></th>

                              <th className="!w-[125px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                Status
                              </th>
                              <th
                                className={`!w-[130px] lg:w-[128px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase`}
                                onClick={() => {
                                  setSortOrder(
                                    sortOrder === "ASC" ? "DESC" : "ASC"
                                  );
                                  setSelectedFilterdata("atoz");
                                  setFilterdata("Sort By");
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Candidate{" "}
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {selectedFilterdata != "atoz" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : sortOrder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  )}
                                </span>
                              </th>
                              <th
                                className="!w-[86px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                onClick={() => {
                                  setSortOrder(
                                    sortOrder === "ASC" ? "DESC" : "ASC"
                                  );
                                  setSelectedFilterdata("percentage");
                                  setFilterdata("Sort By");
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                MATCH %{" "}
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {selectedFilterdata != "percentage" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : sortOrder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  )}
                                </span>
                              </th>

                              {customer?.show_deni && (
                                <th
                                  className="!w-[108px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                  onClick={() => {
                                    setSortOrder(
                                      sortOrder === "ASC" ? "DESC" : "ASC"
                                    );
                                    setSelectedFilterdata("ethnicity");
                                    setFilterdata("Sort By");
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  DE&I{" "}
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {selectedFilterdata != "ethnicity" ? (
                                      <img
                                        src="/images/descendant.svg"
                                        alt="Sort"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : sortOrder === "DESC" ? (
                                      <img
                                        src="/images/asc.svg"
                                        alt="Ascending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="/images/desc.svg"
                                        alt="Descending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    )}
                                  </span>
                                </th>
                              )}
                              {customer?.ismiddleeast && (
                                <th
                                  className={` !w-[100px]  px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase`}
                                  onClick={() => {
                                    setSortOrder(
                                      sortOrder === "ASC" ? "DESC" : "ASC"
                                    );
                                    setSelectedFilterdata("nationality");
                                    setFilterdata("Sort By");
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  NATIONAL{" "}
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {selectedFilterdata != "nationality" ? (
                                      <img
                                        src="/images/descendant.svg"
                                        alt="Sort"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : sortOrder === "DESC" ? (
                                      <img
                                        src="/images/asc.svg"
                                        alt="Ascending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="/images/desc.svg"
                                        alt="Descending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    )}
                                  </span>
                                </th>
                              )}
                              {customer?.isgender && (
                                <th
                                  className="!w-[80px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                  onClick={() => {
                                    setSortOrder(
                                      sortOrder === "ASC" ? "DESC" : "ASC"
                                    );
                                    setSelectedFilterdata("gender");
                                    setFilterdata("Sort By");
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  GENDER{" "}
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {selectedFilterdata != "gender" ? (
                                      <img
                                        src="/images/descendant.svg"
                                        alt="Sort"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : sortOrder === "DESC" ? (
                                      <img
                                        src="/images/asc.svg"
                                        alt="Ascending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="/images/desc.svg"
                                        alt="Descending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    )}
                                  </span>
                                </th>
                              )}
                              {customer?.iscurrentjoblocation && (
                                <th
                                  className="!w-[130px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                  onClick={() => {
                                    setSortOrder(
                                      sortOrder === "ASC" ? "DESC" : "ASC"
                                    );
                                    setSelectedFilterdata("currentloc");
                                    setFilterdata("Sort By");
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  Current Location{" "}
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {selectedFilterdata != "currentloc" ? (
                                      <img
                                        src="/images/descendant.svg"
                                        alt="Sort"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : sortOrder === "DESC" ? (
                                      <img
                                        src="/images/asc.svg"
                                        alt="Ascending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="/images/desc.svg"
                                        alt="Descending"
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                        }}
                                      />
                                    )}
                                  </span>
                                </th>
                              )}
                              <th
                                className="!w-[90px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"
                                onClick={() => {
                                  setSortOrder(
                                    sortOrder === "ASC" ? "DESC" : "ASC"
                                  );
                                  setSelectedFilterdata("totalexp");
                                  setFilterdata("Sort By");
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                TOTAL EXP{" "}
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {selectedFilterdata != "totalexp" ? (
                                    <img
                                      src="/images/descendant.svg"
                                      alt="Sort"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : sortOrder === "DESC" ? (
                                    <img
                                      src="/images/asc.svg"
                                      alt="Ascending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/desc.svg"
                                      alt="Descending"
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    />
                                  )}
                                </span>
                              </th>
                              <th className="!w-[130px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                CONTACT
                              </th>
                              <th className="!w-[180px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                EMAIL
                              </th>
                              <th className="!w-[180px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                LINKEDIN PROF.
                              </th>
                              {customer?.isexperiencesimiliar && (
                                <th className="!w-[120px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Similar Industry
                                </th>
                              )}
                              {customer?.iscustomcriticalskill && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Critical Skill
                                </th>
                              )}
                              {customer?.ishighestqualification && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Highest Edu.
                                </th>
                              )}
                              {customer?.isdrivinglic && (
                                <th className="!w-[120px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Driving License
                                </th>
                              )}
                              {customer?.isarabicspeaking && (
                                <th className="!w-[120px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  SPEAKS ARABIC
                                </th>
                              )}
                              {customer?.isoverqualified && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Over Qual.
                                </th>
                              )}
                              {customer?.isoverexperienced && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Over Exp.
                                </th>
                              )}
                              {customer?.isage && (
                                <th className="!w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  Age
                                </th>
                              )}
                              <th className="!w-[40px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                {}
                              </th>

                              {data[0]?.candidatesdata?.length > 5 && (
                                <th className="!w-[5px]  text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
                                  {}
                                </th>
                              )}
                            </tr>
                          </thead>
                        </table>

                        <div
                          className={` ${
                            noDataAvailable
                              ? "max-h-[calc(100vh-350px)]"
                              : "max-h-[calc(100vh-305px)]"
                          }  overflow-auto shadow-md `}
                          style={{
                            scrollbarWidth: "thin",
                            scrollbarColor: "auto transparent",
                          }}
                        >
                          <table className="w-full border-l border- border-[#E0E0E0] rounded-sm ">
                            <tbody>
                              {data &&
                                data[0]?.candidatesdata?.map((candidate, i) => (
                                  <tr
                                    key={i}
                                    className={`${
                                      i % 2 === 0
                                        ? "bg-[#EDF4FF] border-none"
                                        : "bg-white border-none"
                                    }`}
                                  >
                                    <td className=" !w-[40px] text-center px-2 py-3  text-sm text-gray-700 font-medium text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.isfavorite
                                            ? "Favorite"
                                            : "Mark as Favorite"
                                        }
                                      >
                                        <div>
                                          <MarkFavorite
                                            onClick={(e) =>
                                              btnMarkFavorite(candidate)
                                            }
                                            imgUrl={
                                              candidate?.isfavorite
                                                ? "/images/filled-bookmark.svg"
                                                : "/images/bookmark.svg"
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Tooltip>
                                    </td>
                                    <td className=" !w-[12px] text-center text-[#21D6AA] ">
                                      {candidate?.cvstatus &&
                                      candidate?.screeningnote ? (
                                        <img
                                          onClick={() =>
                                            handleItemClick(candidate)
                                          }
                                          className="w-7 h-7 cursor-pointer"
                                          src="/images/screeningicon.svg"
                                          alt="Screening Icon"
                                        />
                                      ) : (
                                        candidate?.cvstatus === "Screened" && (
                                          <img
                                            onClick={() =>
                                              handleItemClick(candidate)
                                            }
                                            className="w-7 h-7 cursor-pointer"
                                            src={
                                              candidate?.screeningnote === ""
                                                ? "/images/screeningicon2.svg"
                                                : "/images/screeningicon.svg"
                                            }
                                            alt="Screening Icon"
                                          />
                                        )
                                      )}
                                    </td>

                                    <td className="!w-[125px] text-left px-2 py-3 text-[15px]  font-normal ">
                                      <select
                                        className="w-auto m-0 p-0 bg-transparent border text-gray-700  font-normal border-gray-400 rounded-md "
                                        value={
                                          candidate?.cvstatus || "Select Status"
                                        }
                                        onChange={(e) =>
                                          handleStatusChange(
                                            candidate?.analysisid,
                                            e.target.value
                                          )
                                        }
                                      >
                                        {CvStatus?.map((status) => (
                                          <option
                                            key={status?.seqno}
                                            value={status?.cvstatusname}
                                          >
                                            {status?.cvstatusname}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td className=" !w-[130px]  text-left px-2 py-3  text-sm text-gray-700 font-medium text-[15px]">
                                      <div className="flex flex-col gap-1">
                                        <div className="w-full flex justify-between">
                                          <Tooltip
                                            title={
                                              candidate?.candidatename?.length >
                                              18
                                                ? candidate.candidatename
                                                : ""
                                            }
                                          >
                                            {candidate?.candidatename.length >
                                            18
                                              ? `${candidate?.candidatename.substring(
                                                  0,
                                                  18
                                                )}...`
                                              : candidate?.candidatename}
                                          </Tooltip>
                                          <div
                                            className="cursor-pointer"
                                            onClick={(e) =>
                                              navigate("/candidateresult", {
                                                state: candidate?.analysisid,
                                              })
                                            }
                                          >
                                            <img src="/images/arrow.svg" />
                                          </div>
                                        </div>
                                        <div className="text-[#708EA4] text-sm font-normal">
                                          {formatDateTime(
                                            candidate?.createddate
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    <td className=" !w-[86px]  px-2 py-3 text-center text-sm text-gray-700 font-normal text-[15px]">
                                      {candidate?.match_percentage}%{" "}
                                    </td>

                                    {customer?.show_deni && (
                                      <td className=" !w-[108px] text-left px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        <Tooltip
                                          title={
                                            candidate?.ethnicity?.length > 14
                                              ? capitalizeWords(
                                                  candidate?.ethnicity
                                                )
                                              : ""
                                          }
                                        >
                                          {candidate?.ethnicity === "unknown"
                                            ? "-"
                                            : candidate?.ethnicity?.length > 14
                                            ? `${capitalizeWords(
                                                candidate?.ethnicity.substring(
                                                  0,
                                                  14
                                                )
                                              )}...`
                                            : capitalizeWords(
                                                candidate?.ethnicity
                                              )}
                                        </Tooltip>
                                      </td>
                                    )}
                                    {customer?.ismiddleeast && (
                                      <td
                                        className={` !w-[100px] text-left px-2 py-3  font-normal text-[15px] text-gray-700`}
                                      >
                                        {customer?.ismiddleeast ? (
                                          <Tooltip
                                            title={
                                              candidate?.nationality?.length >
                                              15
                                                ? candidate?.nationality
                                                : ""
                                            }
                                          >
                                            {" "}
                                            {candidate?.nationality ===
                                            "unknown"
                                              ? "-"
                                              : candidate?.nationality?.length >
                                                15
                                              ? `${candidate?.nationality.substring(
                                                  0,
                                                  15
                                                )}...`
                                              : candidate?.nationality}
                                          </Tooltip>
                                        ) : (
                                          <text className="flex justify-center w-[100px] items-center  font-normal">
                                            {" "}
                                            -{" "}
                                          </text>
                                        )}
                                      </td>
                                    )}
                                    {customer?.isgender && (
                                      <td className="!w-[80px] text-left   cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.gender === "M"
                                          ? "Male"
                                          : "Female"}
                                      </td>
                                    )}
                                    {customer?.iscurrentjoblocation && (
                                      <td className=" !w-[130px] text-left cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        <Tooltip
                                          title={
                                            candidate?.currentjoblocation
                                              ?.length > 20
                                              ? candidate?.currentjoblocation
                                              : ""
                                          }
                                        >
                                          {candidate?.currentjoblocation
                                            ?.length > 20
                                            ? `${candidate?.currentjoblocation.substring(
                                                0,
                                                20
                                              )}...`
                                            : candidate?.currentjoblocation
                                            ? candidate?.currentjoblocation
                                            : ""}
                                        </Tooltip>
                                      </td>
                                    )}
                                    <td className=" !w-[90px] text-center   cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      {candidate?.totalexp === 0 ? (
                                        <text className="flex justify-center w-full items-center  font-normal">
                                          {" "}
                                          -{" "}
                                        </text>
                                      ) : (
                                        candidate?.totalexp.toFixed(1) + "y"
                                      )}
                                    </td>
                                    <td className="!w-[130px] text-left cursor-paste px-2  py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.phone?.length > 14
                                            ? candidate?.phone
                                            : ""
                                        }
                                      >
                                        {candidate?.phone?.length > 14
                                          ? `${candidate?.phone.substring(
                                              0,
                                              14
                                            )}...`
                                          : candidate?.phone
                                          ? candidate?.phone
                                          : ""}
                                      </Tooltip>
                                    </td>
                                    <td className="!w-[180px] text-left px-2 cursor-paste py-3  text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.email?.length > 18
                                            ? candidate?.email
                                            : ""
                                        }
                                      >
                                        {candidate?.email?.length > 18
                                          ? `${candidate?.email.substring(
                                              0,
                                              18
                                            )}...`
                                          : candidate?.email
                                          ? candidate?.email
                                          : ""}
                                      </Tooltip>
                                    </td>
                                    <td className="!w-[180px] text-left items-center cursor-paste px-2 py-3 text-sm text-gray-700 font-normal text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.linkedinurl?.length > 20
                                            ? candidate?.linkedinurl
                                            : ""
                                        }
                                      >
                                        <a
                                          href={
                                            candidate?.linkedinurl?.startsWith(
                                              "http"
                                            )
                                              ? candidate.linkedinurl
                                              : `https://${candidate?.linkedinurl}`
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-blue"
                                        >
                                          {candidate.linkedinurl?.length > 20
                                            ? `${candidate?.linkedinurl?.substring(
                                                0,
                                                20
                                              )}...`
                                            : candidate?.linkedinurl
                                            ? candidate?.linkedinurl
                                            : ""}
                                        </a>
                                      </Tooltip>
                                    </td>
                                    {customer?.isexperiencesimiliar && (
                                      <td className=" !w-[120px]  text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.isexpinsimilarindustry ===
                                        true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {customer?.iscustomcriticalskill && (
                                      <td className=" !w-[100px]  text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.iscustomcriticalskillyes ===
                                        true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {customer?.ishighestqualification && (
                                      <td className=" !w-[100px] items-center text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.highestqualification ? (
                                          candidate?.highestqualification
                                        ) : (
                                          <div className="!w-full flex justify-center">
                                            {" "}
                                            -{" "}
                                          </div>
                                        )}
                                      </td>
                                    )}
                                    {customer?.isdrivinglic && (
                                      <td className=" !w-[120px] text-center cursor-paste px-2 py-3 text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.isdrivinglic === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {customer?.isarabicspeaking && (
                                      <td className="!w-[120px] text-center items-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.isarabicspeaking === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {/* { customer?.ismiddleeast && ( */}
                                    {customer?.isoverqualified && (
                                      <td
                                        className={` !w-[100px]  items-center text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]`}
                                      >
                                        {candidate?.isoverqualified === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {/* )} */}
                                    {customer?.isoverexperienced && (
                                      <td className=" !w-[100px] items-center text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.isoverexperienced === true
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    )}
                                    {customer?.isage && (
                                      <td className=" !w-[100px] items-center text-center cursor-paste px-2 py-3  text-sm text-gray-700 font-normal text-[15px]">
                                        {candidate?.age ? candidate?.age : "-"}
                                      </td>
                                    )}
                                    <td className="!w-[40px] px-2 py-3 text-[15px]   items-center font-medium text-[#21D6AA]">
                                      <Tooltip title="Download Resume">
                                        <img
                                          className=" items-center cursor-pointer"
                                          src="/images/resumee.svg"
                                          onClick={() =>
                                            downloadResume(candidate?.resumeurl)
                                          }
                                        />
                                      </Tooltip>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : !toggleView ? (
                <>
                  {noDataAvailable ? (
                    <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                      <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                        <p className="text-[30px] text-gray-500 font-bold">!</p>
                      </div>
                      <p className="text-[20px] text-gray-500 font-bold">
                        Data not available
                      </p>
                    </div>
                  ) : (
                    <div
                      className="mx-[15px] max-h-[calc(100vh-240px)] overflow-auto "
                      style={{ scrollbarWidth: "thin", overflowX: "hidden" }}
                    >
                      <div class="full-list">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {data &&
                            data[0]?.candidatesdata?.map((candidate, i) => (
                              <div
                                class="details-listing"
                                key={candidate?.analysisid}
                                style={{
                                  width: "32%",
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <div
                                  class="under-listing "
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    navigate("/candidateresult", {
                                      state: candidate?.analysisid,
                                    })
                                  }
                                >
                                  <p>{candidate?.candidatename}</p>
                                </div>
                                <div
                                  class="under-listing"
                                  style={{ textAlign: "center" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#708EA4",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "paste",
                                      }}
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    >
                                      {candidate?.match_percentage}%
                                    </p>
                                    <img
                                      style={{
                                        width: "20px",
                                        cursor: "pointer",
                                      }}
                                      src="/images/arrow.svg"
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    />
                                    {/* <MarkFavorite
                                      onClick={(e) =>
                                        btnMarkFavorite(candidate)
                                      }
                                      imgUrl={
                                        candidate?.isfavorite
                                          ? "/images/filled-bookmark.svg"
                                          : "/images/bookmark.svg"
                                      }
                                      style={{
                                        width: "70%",
                                        marginLeft: "3px",
                                        marginTop: "4px",
                                        cursor: "pointer",
                                      }}
                                    /> */}
                                    <Tooltip
                                      title={
                                        candidate?.isfavorite
                                          ? "Favorite"
                                          : "Mark as Favorite"
                                      }
                                    >
                                      <div>
                                        <MarkFavorite
                                          onClick={(e) =>
                                            btnMarkFavorite(candidate)
                                          }
                                          imgUrl={
                                            candidate?.isfavorite
                                              ? "/images/filled-bookmark.svg"
                                              : "/images/bookmark.svg"
                                          }
                                          style={{
                                            width: "70%",
                                            marginLeft: "3px",
                                            marginTop: "4px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {noDataAvailable ? (
                    <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                      <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                        <p className="text-[30px] text-gray-500 font-bold">!</p>
                      </div>
                      <p className="text-[20px] text-gray-500 font-bold">
                        Data not available
                      </p>
                    </div>
                  ) : (
                    <div
                      className="mx-[15px] shadow-md "
                      style={{ boxShadow: "0px 0px 20px #5B93ED33" }}
                    >
                      <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0]">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr className="w-full">
                              <th className="2xl:w-[40px] xl:w-[30px] lg:w-[30px] py-4 text-left font-medium text-blue   text-[16px] uppercase"></th>
                              <th className="2xl:w-[110px] xl:w-[90px] lg:w-[90px]   py-4 text-left font-medium text-blue   text-[16px] first-line:uppercase">
                                CANDIDATE
                              </th>
                              <th className="2xl:w-[85px] xl:w-[70px] lg:w-[70px] py-4 text-left font-medium text-blue   text-[16px] uppercase">
                                MATCH&nbsp;%
                              </th>
                              <th className="2xl:w-[90px] xl:w-[50px] lg:w-[50px] py-4 text-left font-medium text-blue   text-[16px] uppercase">
                                RESULT
                              </th>
                              {customer?.show_deni && (
                                <th className="2xl:w-[75px] xl:w-[50px] lg:w-[50px]  py-4 text-left font-medium text-blue   text-[16px] uppercase">
                                  DE&I
                                </th>
                              )}
                              {customer?.ismiddleeast && (
                                <th className="2xl:w-[110px] xl:w-[70px] lg:w-[70px]    py-4 text-left font-medium text-blue   text-[16px] uppercase">
                                  NATIONALITY
                                </th>
                              )}
                              <th className="2xl:w-[80px] xl:w-[50px] lg:w-[50px]  py-4 text-left font-medium text-blue text-[16px] uppercase">
                                AGE
                              </th>
                              <th className="2xl:w-[120px] xl:w-[90px] lg:w-[90px] py-4 text-left font-medium text-blue text-[16px] uppercase">
                                EMAIL
                              </th>
                              <th className="2xl:w-[160px] xl:w-[200px] lg:w-[220px] py-4 text-left font-medium text-blue text-[16px] uppercase"></th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  )}
                  <div
                    className={`mx-[15px] ${
                      noDataAvailable
                        ? "max-h-[calc(100vh-250px)]"
                        : "max-h-[calc(100vh-305px)]"
                    }  overflow-auto shadow-md `}
                    style={{ scrollbarWidth: "none" }}
                  >
                    <div className="">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                          {data &&
                            data[0]?.candidatesdata?.map((candidate, i) => (
                              <tr
                                key={i}
                                className={`${
                                  i % 2 === 0
                                    ? "bg-[#EDF4FF] border-none"
                                    : "bg-white border-none"
                                }`}
                              >
                                <td className="w-[4%] px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  <div>
                                    <Tooltip
                                      title={
                                        candidate?.isfavorite
                                          ? "Favorite"
                                          : "Mark as Favorite"
                                      }
                                    >
                                      <div>
                                        <MarkFavorite
                                          onClick={(e) =>
                                            btnMarkFavorite(candidate)
                                          }
                                          imgUrl={
                                            candidate?.isfavorite
                                              ? "/images/filled-bookmark.svg"
                                              : "/images/bookmark.svg"
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td className="w-[13%] px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  <div>
                                    <div className="flex flex-col gap-3">
                                      <Tooltip
                                        title={
                                          candidate?.candidatename?.length > 12
                                            ? candidate?.candidatename
                                            : ""
                                        }
                                      >
                                        {candidate?.candidatename?.length > 12
                                          ? `${candidate?.candidatename.substring(
                                              0,
                                              12
                                            )}...`
                                          : candidate?.candidatename}
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          candidate?.phone.length > 20
                                            ? candidate.phone
                                            : ""
                                        }
                                      >
                                        {candidate?.phone.length > 20
                                          ? `${candidate.phone.substring(
                                              0,
                                              20
                                            )}...`
                                          : candidate?.phone}
                                      </Tooltip>
                                    </div>
                                  </div>
                                </td>

                                <td
                                  className={`${
                                    customer?.show_deni ? "w-[10%]" : "w-[10%]"
                                  } px-2  py-4 text-[15px] text-[#708EA4]`}
                                >
                                  {candidate?.match_percentage}%
                                </td>

                                <td
                                  className="w-[10%] px-2  py-4 text-[15px] font-medium text-[#21D6AA] cursor-pointer"
                                  onClick={(e) =>
                                    navigate("/candidateresult", {
                                      state: candidate?.analysisid,
                                    })
                                  }
                                >
                                  <div className="flex flex-row gap-1">
                                    <p>VIEW</p>
                                    <img src="/images/arrow.svg" />
                                  </div>
                                </td>
                                {customer?.show_deni && (
                                  <td className="w-[9%] cursor-pointer px-2  py-4 text-sm text-gray-700 font-medium text-[15px]">
                                    <Tooltip
                                      title={
                                        candidate?.ethnicity?.length > 14
                                          ? capitalizeWords(
                                              candidate?.ethnicity
                                            )
                                          : ""
                                      }
                                    >
                                      {candidate?.ethnicity?.length > 14
                                        ? `${capitalizeWords(
                                            candidate?.ethnicity.substring(
                                              0,
                                              14
                                            )
                                          )}...`
                                        : capitalizeWords(candidate?.ethnicity)}
                                    </Tooltip>
                                  </td>
                                )}

                                {/* { customer?.ismiddleeast && ( */}
                                {customer?.ismiddleeast && (
                                  <td
                                    className={` ${
                                      customer?.show_deni
                                        ? "w-[13%]"
                                        : "w-[13%]"
                                    } cursor-paste px-2  py-4  text-sm text-gray-700 font-medium text-[15px]`}
                                  >
                                    {customer?.ismiddleeast ? (
                                      <Tooltip
                                        title={
                                          candidate?.nationality?.length > 15
                                            ? candidate?.nationality
                                            : ""
                                        }
                                      >
                                        {" "}
                                        {candidate?.nationality?.length > 15
                                          ? `${candidate?.nationality.substring(
                                              0,
                                              15
                                            )}...`
                                          : candidate?.nationality}
                                      </Tooltip>
                                    ) : (
                                      <text className="flex justify-center items-center  font-bold">
                                        {" "}
                                        -{" "}
                                      </text>
                                    )}
                                  </td>
                                )}
                                {/* )} */}

                                <td className="w-[9%] cursor-paste px-2  py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  {candidate?.age}
                                </td>
                                <td className="w-[12%] px-2 cursor-paste py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  <Tooltip
                                    title={
                                      candidate?.email?.length > 20
                                        ? candidate?.email
                                        : ""
                                    }
                                  >
                                    {candidate?.email?.length > 20
                                      ? `${candidate?.email.substring(
                                          0,
                                          20
                                        )}...`
                                      : candidate?.email}
                                  </Tooltip>
                                </td>

                                <td
                                  className={`w-[5%] px-2  py-2 text-[15px] font-medium ${
                                    candidate?.profileurl
                                      ? "text-[#21D6AA]"
                                      : "text-gray-400"
                                  } `}
                                >
                                  <Tooltip title="ATS Profile">
                                    <div
                                      className={`flex justify-center gap-1 ${
                                        candidate?.profileurl
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed"
                                      }`}
                                      onClick={() =>
                                        candidate?.profileurl &&
                                        window.open(
                                          candidate?.profileurl,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <p
                                        style={{
                                          color: candidate?.profileurl
                                            ? "#21D6AA"
                                            : "#708ea4",
                                        }}
                                      >
                                        ATS
                                      </p>
                                      <img
                                        src={
                                          candidate?.profileurl
                                            ? "/images/profile-icon.svg"
                                            : "/images/profile-icon-disable.svg"
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </td>

                                <td className="w-[5%] px-2 py-2 text-[15px] font-medium">
                                  <Tooltip title="External Link">
                                    <img
                                      className={` ${
                                        candidate?.externallink
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed"
                                      }`}
                                      src={
                                        candidate?.externallink
                                          ? "/images/external-link.svg"
                                          : "/images/Disabled External Link.svg"
                                      }
                                      onClick={() =>
                                        candidate?.externallink &&
                                        window.open(
                                          candidate?.externallink,
                                          "_blank"
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </td>

                                <td className="w-[5%]  py-2 text-[15px] font-medium text-[#21D6AA]">
                                  <Tooltip title="Download Resume">
                                    <img
                                      className=" cursor-pointer"
                                      src="/images/resumee.svg"
                                      onClick={() =>
                                        downloadResume(candidate?.resumeurl)
                                      }
                                    />
                                  </Tooltip>
                                </td>

                                <td className=" w-[5%] px-2 text-[#708EA4] text-sm font-normal">
                                  {formatDateTime(candidate?.createddate)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default ProcessedCandidateByJob;
