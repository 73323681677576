import axios from "../libs/axios";

const VerifyToken = async (body) => {
  const { token } = body;
  const { data } = await axios.request({
    url: "/api/verifytoken",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      token: token,
    },
  });
  return data;
};

export const auth = () => ({
  VerifyToken,
});
