import "./App.css";
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ProcessedCandidateByJob from "./pages/ProcessedCandidateByJob/ProcessedCandidateByJob";
import ProcessedJobs from "./pages/ProcessedJobs/ProcessedJobs";
import CandidateResult from "./pages/CandidateResult/CandidateResult";
import FavoriteCandidates from "./pages/Favorites/FavoriteCandidates";
import Logout from "./components/Logout/Logout";
import Home from "./pages/Home/Home";
import Feedback from "./pages/Feedback/Feedback";
import Overview from "./pages/Overview/Overview";
import ExportPdfPage from "./pages/ExportPdf/ExportPdfPage";
import ClientSetting from "./pages/ClientSetting/ClientSetting";
import AddNewClient from "./pages/AddNewClient/AddNewClient";
import Prompt from "./pages/Prompt/Prompt";
import EditClientSettings from "./pages/EditClientSettings/EditClientSettings";
import CreatePDF from "./pages/CreatePdf/CreatePdf";
import Users from "./pages/Users/Users";
import AddUser from "./pages/AddUser/AddUser";
import StandardizationJasonFile from "./pages/Standardrization/StandardizationJasonFile";
import Standardrization from "./pages/Standardrization/Standardrization";
import JobDescribtionFile from "./pages/ProcessedJobs/JobDescribtionFile";
import ErrorResume from "./pages/ErrorResume/ErrorResume";
import Joblist from "./pages/JobList/Joblist";
import AddJob from "./pages/JobList/AddJob";
import Screening from "./pages/ScreeningList/Screening";

function App() {
  const userType = localStorage.getItem("usertype");
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProcessedJobs />}></Route>
          <Route path="/processedjobs" element={<ProcessedJobs />}></Route>

          {userType == "A" && (
            <Route path="/prompt" element={<Prompt />}></Route>
          )}

          {userType == "A" && (
            <Route
              path="/editclientsetting"
              element={<EditClientSettings />}
            ></Route>
          )}
          <Route path="/createpdf" element={<CreatePDF />}></Route>
          <Route path="/candidateresult" element={<CandidateResult />} />
          <Route
            path="/processedcandidate"
            element={<ProcessedCandidateByJob />}
          />
          {userType == "A" && (
            <Route path="/errorresume" element={<ErrorResume />} />
          )}
          <Route path="/favorites" element={<FavoriteCandidates />} />
          <Route path="/exportpdf/:analysisid" element={<ExportPdfPage />} />

          <Route path="/Logout" element={<Logout />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/screening" element={<Screening />} />
          <Route path="/jobs" element={<Joblist />} />
          <Route path="/addjobs" element={<AddJob />} />
          <Route path="/editjobs" element={<AddJob />} />
          <Route path="/overview" element={<Overview />} />
          {userType == "A" && (
            <Route path="/setting" element={<ClientSetting />} />
          )}
          {userType == "A" && (
            <Route path="/addnewclient" element={<AddNewClient />}></Route>
          )}
          {userType == "A" && (
            <Route path="/ClientSetting" element={<ClientSetting />}></Route>
          )}
          <Route path="/users" element={<Users />}></Route>
          <Route path="/adduser" element={<AddUser />}></Route>
          <Route path="/edituser" element={<AddUser />}></Route>
          <Route path="/standardization" element={<Standardrization />}></Route>
          <Route
            path="/jobdescribtion"
            element={<JobDescribtionFile />}
          ></Route>
          {userType == "A" && (
            <Route
              path="/standardizationfile/:analysisid"
              element={<StandardizationJasonFile />}
            ></Route>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
