import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    width: "600px",
    maxHeight: "460px",
  },
}));

export default function ScreeningMessagePopup(props) {
  const {
    analysisid,
    screeningNote,
    handleInputChange,
    handleSaveChanges,
    handleClosePopup,
    isPopupOpen,
    errorMessage,
    isSave,
  } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Handle textarea change
  const handleTextChange = (event) => {
    handleInputChange(event);
    setHasUnsavedChanges(true); // Mark as unsaved changes
  };

  // Handle main popup close
  const handleMainClose = () => {
    if (hasUnsavedChanges) {
      setShowConfirmation(true); // Show confirmation if unsaved changes exist
    } else {
      handleClosePopup(); // Close the main popup directly
    }
  };

  // Confirm discard changes
  const handleDiscardChanges = () => {
    setShowConfirmation(false);
    setHasUnsavedChanges(false);
    handleClosePopup();
  };

  // Cancel confirmation
  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <React.Fragment>
      {/* Main Screening Message Popup */}
      <BootstrapDialog
        sx={{ padding: 0 }}
        onClose={handleMainClose}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen && !showConfirmation}
        BackdropProps={{
          onClick: handleMainClose,
        }}
      >
        <Typography>
          <p
            style={{
              textAlign: "left",
              marginTop: "10px",
              marginLeft: "24px",
              fontWeight: "600",
            }}
          >
            Screening Note
            <span style={{ color: "red", marginLeft: "5px" }}>*</span>
          </p>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleMainClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: "green",
          }}
        >
          <img
            style={{ width: "15px", height: "15px" }}
            src="/images/Path 1670.svg"
            alt=""
          />
        </IconButton>
        <DialogContent
          className="w-full"
          style={{ padding: "0px", scrollbarWidth: "thin" }}
        >
          <Typography className="p-3 w-full font-normal text-[15px] text-gray-700">
            <div>
              <div style={{ flex: "1px", width: "100%" }} className="px-3">
                <textarea
                  placeholder="Enter Screening Note"
                  value={screeningNote}
                  onChange={handleTextChange}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "180px",
                    border: "1px solid #708EA4",
                    fontWeight: "normal",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "5px",
                    scrollbarWidth: "thin",
                    opacity: 1,
                    padding: "7px",
                    marginBottom: "12px",
                  }}
                />
                {errorMessage && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {errorMessage}
                  </p>
                )}
              </div>
              <div className="flex w-full justify-between px-3">
                <button
                  type="submit"
                  className="font-rubik font-medium text-black"
                  style={{
                    width: "150px",
                    height: "40px",
                    background: "#21D6AA",
                    borderRadius: "5px",
                    opacity: 1,
                  }}
                  onClick={handleSaveChanges}
                >
                  {isSave ? "SAVE" : "UPDATE"}
                </button>
              </div>
            </div>
          </Typography>
        </DialogContent>
      </BootstrapDialog>

      {/* Confirmation Dialog */}
      <BootstrapDialog
        onClose={handleCancelConfirmation}
        aria-labelledby="confirm-dialog-title"
        open={showConfirmation}
        sx={{
          "& .MuiPaper-root": {
            width: "400px", // Set width to 400px
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCancelConfirmation}
          sx={{
            position: "absolute",
            right: 5,
            top: 8,
            color: "green",
          }}
        >
          <img
            style={{ width: "15px", height: "15px" }}
            src="/images/Path 1670.svg"
          />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <img
              style={{ width: "50px", height: "50px" }}
              src="/images/Path 1681.svg"
              alt=""
            />
          </Typography>
          <Typography
            gutterBottom
            style={{
              font: "normal normal bold 20px/23px Rubik",
              marginTop: "7px",
            }}
          >
            Are you sure to cancel changes?
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "40px",
              marginTop: "7px",
            }}
          >
            <div
              style={{
                background: "#ffff",
                border: "2px solid #6F8EA4",
                padding: "0px 15px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleCancelConfirmation}
            >
              <div
                style={{
                  color: "#708EA4",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "inline-flex",
                  padding: "6px 24px",
                }}
              >
                NO
              </div>
            </div>
            <div
              style={{
                background: "#E77388",
                borderRadius: "5px",
                padding: "0px 15px",
                cursor: "pointer",
              }}
              onClick={handleDiscardChanges}
            >
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "inline-flex",
                  padding: "6px 24px",
                }}
              >
                YES
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
