import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import { storage } from "../../libs/localStorage";
import { Grid, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Pagination from "../../components/Pagination/Pagination";
import { useJobsData } from "../../data/jobsdata";
import { Link, useNavigate } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MarkFavorite from "../../components/MarkFavorite/MarkFavorite";
import { toast, ToastContainer } from "react-toastify";

const filterData = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "active",
    name: "Active",
  },
  {
    id: "inactive",
    name: "InActive",
  },
];

const Joblist = () => {
  const { GetJobList, MarkJobFavorite, UpdateJob } = useJobsData();
  const { storeToken, retrieveToken, clearToken } = storage();
  const token = retrieveToken();
  const [data, setData] = useState(null);
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const [selectedFilterdata, setSelectedFilterdata] = useState("active");
  const [filterdata, setFilterdata] = useState("Active");
  const secondDropdownRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "job-list";
  const pageSize = 10;
  const [totalCounts, setTotalCounts] = useState(null);
  const totalPages = Math.ceil(totalCounts / pageSize);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  // const customerid = Number(localStorage.getItem("customerid"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const searchTextChange = (event) => {
    setSearchText(event.target.value);
    setSelectedFilterdata(selectedFilterdata);
    setFilterdata(filterdata);
  };

  const fetchData = async (searchText = "") => {
    try {
      const response = await GetJobList({
        pageno: currentPage,
        pagesize: pageSize,
        search: searchText,
        jobfilter: selectedFilterdata,
      });

      if (response?.data == (undefined || null)) {
        setData([]);
        setTotalCounts(0);
        setNoDataAvailable(true);
      } else {
        setData(response?.data);
        response?.data[0]?.totalcount &&
          setTotalCounts(response?.data[0]?.totalcount);
        setNoDataAvailable(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      if (currentPage > 0) {
        await fetchData(searchText);
      }
    };

    fetchDataAsync();
  }, [currentPage]);

  useEffect(() => {
    try {
      if (token) {
        // await fetchJobs(searchText);
        setCurrentPage(1);
      } else {
        window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handlePageChange = async (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
    }
  };

  const btnSearch = async () => {
    currentPage == 1 ? await fetchData(searchText) : setCurrentPage(1);
  };

  const clearSearch = async () => {
    setSearchText("");
    currentPage == 1 ? await fetchData() : setCurrentPage(1);
  };

  useEffect(() => {
    clearSearch();
  }, [selectedFilterdata]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    lineHeight: "24px",
    color: theme.palette.text.secondary,
  }));

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Only last 2 digits of the year
    return `${month}-${day}-${year}`;
  }

  const btnMarkFavorite = (job) => {
    MarkJobFavorite({
      jobid: job?.jobid,
      isfavorite: !job?.isfavorite,
    })
      .then((response) => {
        const itemIndex = data?.findIndex((item) => item?.jobid === job?.jobid);
        if (itemIndex !== -1) {
          const updatedJobData = [...data];
          updatedJobData[itemIndex] = {
            ...updatedJobData[itemIndex],
            isfavorite: !job?.isfavorite,
          };
          setData(updatedJobData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const btnActive = (job) => {
    UpdateJob({
      jobid: job?.jobid,
      jobtitle: job?.jobtitle,
      employmenttype: job?.employmenttype,
      jobdescription: job?.jobdescription,
      isactive: !job?.isactive,
      atsjobcode: job?.atsjobcode,
    })
      .then((response) => {
        const itemIndex = data?.findIndex((item) => item?.jobid === job?.jobid);
        if (itemIndex !== -1) {
          const updatedJobData = [...data];
          updatedJobData[itemIndex] = {
            ...updatedJobData[itemIndex],
            isactive: !job?.isactive,
          };
          setData(updatedJobData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const btnRedirectProcessCand = (params) => {
    if (params?.totalcvs > 0) {
      navigate("/processedcandidate", { state: { jobid: params?.jobid } });
    } else {
      toast.error(
        <span
          style={{
            font: "rubik regular",
            color: "black",
            fontWeight: "normal",
            fontSize: "15px",
          }}
        >
          Not Resumes Found
        </span>
      );
    }
  };
  const toggleSecondDropdown = () => {
    setSecondIsOpen((prev) => !prev);
  };

  const handleSecondOptionClick = (Secondoption) => {
    setSelectedFilterdata(Secondoption.id);
    setFilterdata(Secondoption.name);
    toggleSecondDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setSecondIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // const truncateHtml = (html, limit) => {
  //   let characterCount = 0;
  //   let truncatedHtml = "";

  //   // Parse the HTML and create a TreeWalker to iterate over text nodes
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(html, "text/html");
  //   const walker = doc.createTreeWalker(
  //     doc.body,
  //     NodeFilter.SHOW_TEXT,
  //     null,
  //     false
  //   );

  //   // Traverse each text node and add content until reaching the character limit
  //   while (walker.nextNode()) {
  //     const currentNode = walker.currentNode;
  //     const remainingChars = limit - characterCount;

  //     if (remainingChars <= 0) break; // Stop if limit reached

  //     if (currentNode.nodeValue.length > remainingChars) {
  //       // If the node's text is longer than the remaining space, truncate it
  //       currentNode.nodeValue =
  //         currentNode.nodeValue.substring(0, remainingChars) + "...";
  //       characterCount = limit; // Ensure limit reached
  //     } else {
  //       characterCount += currentNode.nodeValue.length;
  //     }
  //   }

  //   // Serialize the updated HTML back into a string
  //   const serializeNode = (node) => {
  //     if (node.nodeType === Node.TEXT_NODE) {
  //       return node.nodeValue;
  //     }
  //     const tagOpen = `<${node.nodeName.toLowerCase()}${Array.from(
  //       node.attributes
  //     )
  //       .map((attr) => ` ${attr.name}="${attr.value}"`)
  //       .join("")}>`;
  //     const tagClose = `</${node.nodeName.toLowerCase()}>`;
  //     return (
  //       tagOpen +
  //       Array.from(node.childNodes).map(serializeNode).join("") +
  //       tagClose
  //     );
  //   };

  //   truncatedHtml = Array.from(doc.body.childNodes).map(serializeNode).join("");
  //   return truncatedHtml;
  // };

  return (
    <div>
      <ToastContainer />
      <Helmet>
        <style>{"body { background-color: #F3F5F9;}"}</style>
        <title>Job List</title>
      </Helmet>

      {isMobile ? (
        <>
          <Navbar data={activetab} />
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
          </Helmet>

          <React.Fragment>
            <div
              style={{
                margin: "18px 10px 8px 10px",
              }}
            >
              <div class="search-containers">
                <input
                  type="text"
                  id="search"
                  placeholder="Search Job"
                  value={searchText}
                  onChange={searchTextChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") btnSearch();
                  }}
                />

                {searchText && (
                  <button
                    style={{
                      position: "absolute",
                      right: "50px",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={clearSearch}
                  >
                    <img
                      src="/images/greenCross1.svg"
                      style={{ width: "10px", height: "10px" }}
                      alt="Clear"
                    />
                  </button>
                )}
                <button type="submit" onClick={() => btnSearch()}>
                  <img
                    style={{ background: "inherit" }}
                    src="/images/Search Icon.svg"
                  />
                </button>
              </div>
            </div>
            <div className="relative px-[10px]">
              <button
                onClick={toggleSecondDropdown}
                className={`bg-white p-5 w-full flex items-center justify-between border-none shadow-[0_0_20px_#5b93ed33] h-[5px] text-[15px] ${
                  isSecondOpen ? "rounded-t-md text-black" : "rounded-md"
                }`}
              >
                {filterdata || " Active "}
                {!isSecondOpen ? (
                  <img src="images/custom-arrow.svg" alt="custom" />
                ) : (
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    src="images/custom-arrow.svg"
                    alt="custom"
                  />
                )}
              </button>

              {isSecondOpen && (
                <div className="absolute w-[95%] bg-white px-[10px] shadow-[0px_10px_20px_#5b93ed33] rounded-b-lg">
                  <div className="pl-2">
                    {filterData &&
                      filterData?.map((data, i) => (
                        <p
                          className="cursor-pointer border-t-2 border-t-[#5b93ed33] border-b-0 text-[15px] p-[5px]"
                          key={i}
                          onClick={() => handleSecondOptionClick(data)}
                        >
                          {data?.name}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </div>
            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <div style={{ marginBottom: "25px" }}>
                <Grid
                  container
                  spacing={2}
                  columns={isMobile ? 1 : { xs: 4, sm: 8, md: 12 }}
                >
                  {data &&
                    data?.map((item, index) => (
                      <Grid item xs={2} sm={4} md={4}>
                        <Item
                          style={{
                            marginTop: "15px",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                            className=" font-rubik font-bold "
                          >
                            <div className="text-left text-blue">
                              DATE
                              <p className="font-normal text-[#1F2E39] ">
                                {formatDate(item?.createddate)}
                              </p>
                            </div>
                            <div className="text-right text-blue">
                              JOB ID
                              <div className="flex flex-row gap-2">
                                <Tooltip
                                  title={
                                    item?.isfavorite
                                      ? "Favorite"
                                      : "Mark as Favorite"
                                  }
                                >
                                  <div>
                                    <MarkFavorite
                                      onClick={(e) =>
                                        btnMarkFavorite({
                                          jobid: item?.jobid,
                                          isfavorite: item?.isfavorite,
                                        })
                                      }
                                      imgUrl={
                                        item?.isfavorite
                                          ? "/images/filled-bookmark.svg"
                                          : "/images/bookmark.svg"
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </Tooltip>

                                <p
                                  className="font-semibold  text-[#21D6AA] cursor-pointer"
                                  onClick={() =>
                                    navigate("/jobdescribtion", {
                                      state: {
                                        jobid: item?.jobid,
                                        jobcode: item?.atsjobcode,
                                      },
                                    })
                                  }
                                >
                                  {" "}
                                  {item?.atsjobcode}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                            className=" font-rubik font-bold"
                          >
                            <div className="text-left text-blue">
                              JOB TITLE
                              <p className="font-normal text-[#1F2E39]">
                                {" "}
                                {item?.jobtitle
                                  ? item.jobtitle.length > 19
                                    ? `${item.jobtitle.substring(0, 19)}...`
                                    : item.jobtitle
                                  : ""}
                              </p>
                            </div>
                            <div className="text-right text-blue">
                              Sourced
                              <div className="flex flex-row gap-1 items-center">
                                <span className="cursor-text font-normal text-[#1F2E39] text-right tabular-nums inline-block min-w-[40px]">
                                  {item?.totalcvs}
                                </span>
                                {item?.totalcvs > 0 && (
                                  <div
                                    onClick={() => btnRedirectProcessCand(item)}
                                  >
                                    <img
                                      src="/images/arrow.svg"
                                      alt=""
                                      className="w-[19px] h-[19pxS] cursor-pointer"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className=" font-rubik font-bold text-left text-blue">
                              Screened
                              <p className="font-normal text-[#1F2E39]">
                                {item?.screenedcount}
                              </p>
                            </div>
                            <div className=" font-rubik font-bold text-left text-blue">
                              Interviewed
                              <p className="font-normal text-[#1F2E39] ">
                                {item?.interviewedcount}
                              </p>
                            </div>
                            <div className=" font-rubik font-bold text-left text-blue">
                              Hired
                              <p className="font-normal text-[#1F2E39] ">
                                {item?.hiredcount}
                              </p>
                            </div>
                          </div>

                          <div className=" font-rubik font-bold text-left text-blue">
                            JOB TYPE
                            <p className="font-normal text-[#1F2E39]">
                              {item?.employmenttype}
                            </p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className=" flex flex-row gap-2">
                              <span
                                style={{
                                  color: "#1F2E39",
                                  font: "normal normal medium 15px/23px Rubik",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                }}
                              >
                                ACTIVE
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item?.isactive}
                                  onChange={(e) => btnActive(item)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            <div
                              className="uppercase cursor-pointer text-[#21D6AA]"
                              onClick={() => {
                                navigate("/editjobs", {
                                  state: {
                                    job: item,
                                  },
                                });
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                position: "sticky",
                bottom: "0px",
              }}
            >
              <p
                class="Add-Client"
                onClick={() => {
                  navigate("/addjobs", {
                    state: {},
                  });
                }}
                style={{
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "500",
                  display: "inline-flex",
                  padding: "12px 28px",
                }}
              >
                ADD NEW JOB
              </p>
            </div>
            <Pagination
              canNextPage={currentPage < totalPages}
              canPrevPage={currentPage > 1}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </React.Fragment>
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Navbar data={activetab} />
          <Box
            className="px-6 pt-2"
            component="main"
            sx={{ flexGrow: 1, height: "100vh" }}
          >
            <div>
              <div className="mb-3">
                <HeaderBar title="Job List" toggleDrawer={toggleDrawer} />
              </div>

              <div className=" flex flex-row justify-between px-3 mb-3 ">
                <div
                  className="search-container"
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                  }}
                >
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Job"
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingLeft: "10px",
                      width: "200px",
                      flex: 1,
                    }}
                    value={searchText}
                    onChange={searchTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") btnSearch();
                    }}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "40px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "10px", height: "10px" }}
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingTop: "9px",
                    }}
                    type="submit"
                    onClick={() => btnSearch()}
                  >
                    <img
                      style={{ background: "inherit" }}
                      src="/images/Search Icon.svg"
                    />
                  </button>
                </div>
                <div className="flex flex-row gap-5">
                  <div
                    className="relative w-[150px] z-[99]"
                    ref={secondDropdownRef}
                  >
                    <button
                      onClick={toggleSecondDropdown}
                      className={`bg-white p-5 w-full flex items-center justify-between shadow-[0_0_20px_#5b93ed33] h-[5px] text-[15px] border-0 ${
                        isSecondOpen ? "rounded-t-lg text-black" : "rounded-lg"
                      }`}
                    >
                      {filterdata || "Active"}
                      {!isSecondOpen ? (
                        <img src="images/custom-arrow.svg" alt="custom-arrow" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                          alt="custom-arrow"
                        />
                      )}
                    </button>

                    {isSecondOpen && (
                      <div className="absolute bg-white w-full shadow-[0_10px_20px_#5b93ed33] rounded-b-lg">
                        <div>
                          {filterData &&
                            filterData.map((data, i) => (
                              <p
                                className="mr-[15px] ml-[15px] cursor-pointer border-t-2 border-t-[#5b93ed33] border-b-0 text-[15px] p-[5px]"
                                key={i}
                                onClick={() => handleSecondOptionClick(data)}
                              >
                                {data?.name}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center cursor-pointer  justify-end">
                    <p
                      className="bg-[#21D6AA] py-[7px] px-[18px] rounded-[5px] text-white text-sm font-medium "
                      onClick={() => {
                        navigate("/addjobs", {
                          state: {},
                        });
                      }}
                    >
                      ADD&nbsp;NEW&nbsp;JOB
                    </p>
                  </div>
                </div>
              </div>

              {noDataAvailable ? (
                <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                  <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                    <p className="text-[30px] text-gray-500 font-bold">!</p>
                  </div>
                  <p className="text-[20px] text-gray-500 font-bold">
                    Data not available
                  </p>
                </div>
              ) : (
                <div className="mx-[10px] shadow-md ">
                  <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0]">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="w-[30px] px-2 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            {" "}
                          </th>
                          <th className="w-[90px] px-2 pr-2 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Date
                          </th>
                          <th className="w-[120px] px-2 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Job id
                          </th>
                          <th className="w-[140px] px-2 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Job Title
                          </th>
                          {/* <th className="w-[240px] px-2 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Job Description
                          </th> */}
                          <th className="w-[100px] px-2 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            Type
                          </th>
                          <th className="w-[90px] px-2 py-5 text-left font-medium text-blue text-[16px]  tracking-wider">
                            Sourced
                          </th>
                          <th className="w-[70px] px-2 py-5 text-right font-medium text-blue text-[16px]  tracking-wider">
                            Screened
                          </th>
                          <th className="w-[70px] px-2 py-5 text-right font-medium text-blue text-[16px]  tracking-wider">
                            Interviewed
                          </th>
                          <th className="w-[70px] px-2 py-5 text-right font-medium text-blue text-[16px]  tracking-wider">
                            Hired
                          </th>
                          <th className="w-[40px] px-2 py-5 text-right font-medium text-blue text-[16px]  tracking-wider">
                            Active
                          </th>
                          <th className="w-[40px] px-2 py-5 text-left font-medium text-blue text-[16px] uppercase tracking-wider">
                            {" "}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              )}
              <div
                className="mx-[10px] max-h-[calc(100vh-327px)] overflow-auto shadow-md "
                style={{ scrollbarWidth: "thin" }}
              >
                <div className="">
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200 font-rubik">
                      {data?.map((item, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0
                              ? "bg-[#EDF4FF] border-none"
                              : "bg-white border-none"
                          }`}
                        >
                          <td className="w-[30px] px-2 py-4  text-sm text-gray-700 ">
                            <Tooltip
                              title={
                                item?.isfavorite
                                  ? "Favorite"
                                  : "Mark as Favorite"
                              }
                            >
                              <div>
                                <MarkFavorite
                                  onClick={(e) =>
                                    btnMarkFavorite({
                                      jobid: item?.jobid,
                                      isfavorite: item?.isfavorite,
                                    })
                                  }
                                  imgUrl={
                                    item?.isfavorite
                                      ? "/images/filled-bookmark.svg"
                                      : "/images/bookmark.svg"
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </Tooltip>
                          </td>
                          <td className="w-[90px] px-2 py-4  text-sm text-gray-700 ">
                            {formatDate(item?.createddate)}
                          </td>
                          <td
                            className="w-[120px] px-2 py-4 text-[#21D6AA] text-sm font-semibold cursor-pointer"
                            onClick={() =>
                              navigate("/jobdescribtion", {
                                state: {
                                  jobid: item?.jobid,
                                  jobcode: item?.atsjobcode,
                                },
                              })
                            }
                          >
                            {item?.atsjobcode
                              ? item.atsjobcode.length > 10
                                ? `${item.atsjobcode.substring(0, 10)}...`
                                : item.atsjobcode
                              : ""}
                          </td>
                          <td className="px-2 py-4  text-sm text-gray-700 w-[140px]">
                            {item?.jobtitle
                              ? item.jobtitle.length > 16
                                ? `${item.jobtitle.substring(0, 16)}...`
                                : item.jobtitle
                              : ""}
                          </td>
                          {/* <td className="px-2 py-4 text-sm text-gray-700 w-[240px]">
                            {item?.jobdescription ? (
                              item.jobdescription.length > 30 ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item?.jobdescription?.substring(0, 30) +
                                      "...", // Truncate job description to 30 characters
                                  }}
                                />
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.jobdescription, // Here, we pass the HTML content
                                  }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </td> */}
                          <td className="px-2 py-4  text-sm text-gray-700 w-[100px]">
                            {item?.employmenttype}
                          </td>
                          <td className=" px-4 py-4 w-[120px]">
                            <div className="flex flex-row gap-1">
                              <span className="cursor-text text-sm text-gray-700 text-right tabular-nums inline-block min-w-[40px]">
                                {item?.totalcvs}
                              </span>
                              {item?.totalcvs > 0 && (
                                <div
                                  onClick={() => btnRedirectProcessCand(item)}
                                >
                                  <img
                                    src="/images/arrow.svg"
                                    alt=""
                                    className="w-[19px] h-[19pxS] cursor-pointer"
                                  />
                                </div>
                              )}
                            </div>
                          </td>

                          <td className="px-2  py-4 text-center text-sm text-gray-700 w-[80px]">
                            {item?.screenedcount}
                          </td>
                          <td className="px-2  py-4 text-right text-sm text-gray-700 w-[80px]">
                            {item?.interviewedcount}
                          </td>
                          <td className="px-2  py-4 text-right text-sm text-gray-700 w-[80px]">
                            {item?.hiredcount}
                          </td>

                          <td className=" text-right py-4  text-sm text-gray-700 w-[70px]">
                            <Tooltip
                              title={item?.isactive ? "Active" : "In Active"}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item?.isactive}
                                  onChange={(e) => btnActive(item)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </Tooltip>
                          </td>
                          <td className="px-4 py-4  text-sm text-gray-700 w-[50px]">
                            <Tooltip title={"Edit"}>
                              <img
                                className="w-[18px] h-[18px] cursor-pointer"
                                src="/Group 247.svg"
                                alt="edit_icons"
                                onClick={() => {
                                  navigate("/editjobs", {
                                    state: {
                                      job: item,
                                    },
                                  });
                                }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {noDataAvailable ? (
                ""
              ) : (
                <Pagination
                  canNextPage={currentPage < totalPages}
                  canPrevPage={currentPage > 1}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              )}
            </div>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Joblist;
