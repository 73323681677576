import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetDownloadUrl = async (body) => {
  const { url } = body;
  const { data } = await axios.request({
    url: "/v1/getdownloadurl",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      url: url,
    },
  });
  return data;
};

const GetOverviewCounts = async (body) => {
  const { customer, filterdate } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getoverviewcount",
      customer: customer,
      filterdate: filterdate,
    },
  });
  return data;
};

const GetAllCustomers = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getallcustomer",
    },
  });
  return data;
};

export const commonData = () => ({
  GetDownloadUrl,
  GetOverviewCounts,
  GetAllCustomers,
});
